import { render, staticRenderFns } from "./NavControls.vue?vue&type=template&id=200a40de&scoped=true&"
import script from "./NavControls.vue?vue&type=script&lang=js&"
export * from "./NavControls.vue?vue&type=script&lang=js&"
import style0 from "./NavControls.vue?vue&type=style&index=0&id=200a40de&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200a40de",
  null
  
)

export default component.exports