<template>
  <div class="game">
    <NavControls
      @clear="clear"
      @play="play"
      @pause="pause"
      @dimension="dimension = !dimension"
      :isPlaying="isPlaying"
      :isMobile="isMobile"
      :dimension="dimension"
    />

    <div class="grid-2d" v-if="dimension">
      <div class="grid">
        <div class="row" v-for="(row, i) in grid" :key="i">
          <div
            v-for="(cell, j) in row"
            :key="j"
            :class="`cell
               ${cell.alive ? 'alive' : 'dead'}`"
            @click="cellToggle(i, j)"
          ></div>
        </div>
      </div>
    </div>

    <a-scene vr-mode-ui="enabled: false" background="color: #1F1B24" v-else>
      <a-entity
        camera
        look-controls
        cursor="rayOrigin: mouse"
        raycaster="objects: .interactable"
        orbit-controls="minDistance: 0.5; maxDistance: 180; initialPosition:0 5 15"
      ></a-entity>

      <a-entity class="3d-grid" position="-5 5 0" rotation="-180 0 0">
        <a-entity
          class="row-3d"
          v-for="(row, y) in grid"
          :key="y"
          :position="`0 ${y} 0`"
        >
          <a-entity v-for="(cell, x) in row" :key="x">
            <Atom
              v-if="cell.alive"
              :position="`${x} 0 0`"
              scale="0.3 0.3 0.3"
              wireframe="true"
            />
            <a-icosahedron
              v-else
              :position="`${x} 0 0`"
              scale="0.9 0.9 0.9"
              color="white"
              radius="0.5"
              class="interactable"
              wireframe="true"
              @mouseenter="cubeHover($event, 'red', true)"
              @mouseleave="cubeHover($event, 'white', false)"
              @click="cellToggle(y, x)"
            ></a-icosahedron>
          </a-entity>
        </a-entity>
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import NavControls from "@/components/NavControls.vue";
import Atom from "@/components/Atom.vue";

export default {
  name: "setup",
  components: { NavControls, Atom },
  data: function () {
    return {
      grid: [],
      size: 10,
      tick: null,
      dimension: false,
      isPlaying: false,
      isMobile: false,
    };
  },
  mounted: function () {
    this.gridGen("init");
    if (AFRAME.utils.device.isMobile()) {
      this.isMobile = true;
    }
  },
  methods: {
    cubeHover: function (hoverCube, color, enter) {
      let cube = hoverCube.target;
      cube.setAttribute("color", color);
    },
    clear: function () {
      this.grid = [];
      this.isPlaying = false;
      this.gridGen("init");
      clearInterval(this.tick);
    },
    pause: function () {
      this.isPlaying = !this.isPlaying;
      clearInterval(this.tick);
    },
    play: function () {
      this.isPlaying = !this.isPlaying;
      this.tick = setInterval(
        function () {
          this.phase();
        }.bind(this),
        500
      );
    },
    phase: function () {
      for (let i = 0; i < this.size; i++) {
        for (let j = 0; j < this.size; j++) {
          this.surroundCheck(i, j);
        }
      }
      this.eliminateAndBirth();
      this.gridGen("newPhase");
    },
    surroundCheck: function (row, cell) {
      let grid = this.grid;
      let cellAlive = grid[row][cell].alive;
      if (cellAlive) {
        if (row <= this.size && cell <= this.size) {
          for (let i = -1; i < 2; i++) {
            for (let j = -1; j < 2; j++) {
              let checkI = row + i;
              let checkJ = cell + j;
              // if is in board range count as neighbor
              if (
                (i !== 0 || j !== 0) &&
                checkI >= 0 &&
                checkI < this.size &&
                checkJ >= 0 &&
                checkJ < this.size
              ) {
                grid[checkI][checkJ].count++;
              }
            }
          }
        }
      }
    },
    eliminateAndBirth: function () {
      for (let i = 0; i < this.grid.length; i++) {
        const row = this.grid[i];
        for (let j = 0; j < row.length; j++) {
          const cell = row[j];

          if (cell.alive && (cell.count === 2 || cell.count === 3)) {
            cell.alive = true;
          }
          if (!cell.alive && cell.count === 3) {
            cell.alive = true;
          }
          if (cell.alive && cell.count < 2) {
            cell.alive = false;
          }
          if (cell.alive && cell.count > 3) {
            cell.alive = false;
          }
        }
      }
    },
    cellToggle: function (row, cell) {
      this.grid[row][cell].alive = !this.grid[row][cell].alive;
    },

    gridGen: function (type) {
      // generates grid
      for (let i = 0; i < this.size; i++) {
        var row = []; // row in grid
        for (let j = 0; j < this.size; j++) {
          if (type === "init") row.push({ alive: false, count: 0 });
          else this.grid[i][j].count = 0;
        }
        if (type === "init") this.grid.push(row);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1f1b24;
  height: 100vh;
}
a-scene {
  height: 50%;
}
.grid-2d {
  margin-top: 70px;
  height: 50%;
}
.row {
  display: flex;
  .cell {
    cursor: pointer;
    min-width: 28px;
    min-height: 28px;
    &:hover {
      background: rgba(255, 255, 255, 0.527);
    }
    &.dead {
      border: 1px solid white;
    }
    &.alive {
      color: white;
      background: white;
      border: 1px solid white;
    }
  }
}
</style>
