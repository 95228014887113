<template>
  <nav class="nav-controls">
    <div class="logo" title="Conway's Game Of Life">
      <h1>{{ !isMobile ? "Conway's Game Of Life" : "CGOL" }}</h1>
    </div>
    <div class="controls">
      <div class="btns">
        <button
          :class="`btn toggle-game ${isPlaying ? 'pause' : 'play'}`"
          :title="isPlaying ? 'pause' : 'play'"
          @click="!isPlaying ? $emit('play') : $emit('pause')"
        ></button>
        <button
          v-show="isPlaying"
          class="btn clear"
          title="clear/reset grid"
          @click="$emit('clear')"
        ></button>
      </div>
      <div class="toggle-dimensions">
        <label for="switch" :class="dimension ? 'lit' : ''">2D</label>
        <label class="switch">
          <input id="switch" type="checkbox" checked @change="$emit('dimension')" />
          <span class="slider round"></span>
        </label>
        <label for="switch" :class="dimension ? '' : 'lit'">3D</label>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "nav-controls",
  props: ["isPlaying", "isMobile", "dimension"],
};
</script>
<style scoped lang="scss">
$primary: #bb86fc;
$primaryTwo: #3700b3;
$secondary: #03dac6;

nav {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  top: 0;
  background: #1f1b24;
  box-shadow: 0px 2px 7px $primary;
  z-index: 10;
  .controls {
    display: flex;
    align-items: center;
    .btns {
        width: 100px;
    }
  }
}
.btn {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: none;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 1px 2px $secondary, 2px 3px $secondary, 3px 4px $secondary;
  margin: 0 7px;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover {
    position: relative;
    top: 1px;
    left: 1px;
    border: 2px solid $secondary;
    box-shadow: 1px 2px $secondary, 2px 3px $secondary, 2px 4px 12px $secondary;
  }
  &:focus {
    outline: none;
  }
  &.toggle-game {
    &.play {
      background-image: url("/icons/play.svg");
    }
    &.pause {
      background-image: url("/icons/pause.svg");
    }
  }
  &.clear {
    background-image: url("/icons/clear.svg");
  }
}
.logo {
  min-width: 35px;
  min-height: 35px;
  color: white;
  border-radius: 20px;
  border: 2px solid white;
  box-shadow: 1px 2px $secondary, 2px 3px $secondary, 2px 4px 12px $secondary;
  h1 {
    font-size: 30px;
    margin: 0;
    padding: 10px;
  }
}

.toggle-dimensions {
  display: flex;
  align-items: center;
  color: white;
  font-size: 2.5rem;
  padding-left: 26px;
  transition: .5;
  .lit {
    color: $secondary;
    text-shadow: 1px 2px $primary, 2px 3px 5px $primary;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: $primaryTwo;
  box-shadow: 0px 2px 7px $primaryTwo;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>