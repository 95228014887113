<template>
  <a-entity class="atom">
    <a-sphere
      color="#FF926B"
      position="0 -0.5 0"
      radius="0.8"
      animation="property: position;from: 0 -0.5 0; to: 0 0.5 0; dur: 2000; easing: easeInOutQuad; loop: true; dir: alternate"
    ></a-sphere>
    <a-entity
      class="rotation-empty"
      animation="property: rotation;to: 0 360 0; dur: 3000; easing: linear; loop: true; dir: normal;"
    >
      <a-torus color="gray" radius="1.5" radius-tubular="0.01"></a-torus>
      <a-entity
        class="rotation-empty"
        animation="property: rotation;to: 0 0 360; dur: 3000; easing: linear; loop: true; dir: normal;"
      >
        <a-icosahedron
          color="green"
          position="-1.5 0 0"
          radius="0.3"
        ></a-icosahedron>
      </a-entity>
    </a-entity>
  </a-entity>
</template>
<script>
export default {
  name: "atom",
};
</script>
